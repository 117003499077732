import {
  social,
  employment,
  education,
  driver,
  criminal,
  identity,
  address,
  psycho,
  candidateAvatar,
} from "../assets/candidateDetail";
import { discrepancy, inProgress, traqChecked } from "../assets/checkStatus";
import avatar from "../assets/noAvatar.png";

export const candidateDefaultAvatar = candidateAvatar;
export const userDefaultAvatar = avatar;

// FIXED DATA
export const acceptTypes = "image/jpeg,image/jpg,image/png,application/pdf";

export const collarButtons = [
  {
    title: "Blue Collar",
    value: "blue_collar",
  },
  {
    title: "White Collar",
    value: "white_collar",
  },
];

export const checks = [
  { name: "criminal", label: "Criminal", image: criminal },
  { name: "education", label: "Education", image: education },
  { name: "employment", label: "Employment", image: employment },
  { name: "driver", label: "Driver", image: driver },
  { name: "aadhaar", label: "Identity", image: identity },
  { name: "address", label: "Address", image: address },
  { name: "psycho", label: "Psycho", image: psycho },
  { name: "credit", label: "Credit", image: identity },
  { name: "social", label: "Negative web & media", image: social },
];

export const checkStatus = [
  { name: "completed", humanReadable: 'TraqChecked', image: traqChecked, highlightColor: 'green' },
  { name: "pending", humanReadable: 'Pending', image: inProgress, highlightColor: '#dbb628' },
  { name: "discrepancy_found", humanReadable: 'Discrepancy', image: discrepancy, highlightColor: 'red' },
  { name: 'minor_discrepancy_found', humanReadable: 'Minor Discrepancy', image: discrepancy, highlightColor: 'red' },
  { name: 'insufficient', humanReadable: 'Insufficiency', image: discrepancy, highlightColor: '#dbb628' },
  { name: 'on_hold', humanReadable: 'On Hold', image: discrepancy, highlightColor: '#dbb628' },
  { name: "amber", humanReadable: 'Amber', image: inProgress, highlightColor: 'orange' },
  { name: "case_stopped", humanReadable: 'Case Stopped', image: discrepancy, highlightColor: '#dbb628' },
  { name: "qc_rejected", humanReadable: 'QC Rejected', image: discrepancy, highlightColor: '#dbb628' },
  { name: "form_not_filled", humanReadable: 'Form Not Filled', image: inProgress, highlightColor: '#dbb628' },
];

// FIXED FOR SMALL CHART
export const smallChart_style = [
  {
    label: "Today",
    fill: "start",
    borderWidth: 1.5,
  },
];
export const smallChart_randomData = [
  {
    backgroundColor: "rgba(0, 184, 216, 0.1)",
    borderColor: "rgb(0, 184, 216)",
    data: [1, 2, 1, 3, 5, 4, 7],
  },
  {
    backgroundColor: "rgba(23,198,113,0.1)",
    borderColor: "rgb(23,198,113)",
    data: [1, 2, 3, 3, 3, 4, 4],
  },
  {
    backgroundColor: "rgba(255,180,0,0.1)",
    borderColor: "rgb(255,180,0)",
    data: [2, 3, 3, 3, 4, 3, 3],
  },
  {
    backgroundColor: "rgba(255,65,105,0.1)",
    borderColor: "rgb(255,65,105)",
    data: [1, 7, 1, 3, 1, 4, 8],
  },
  {
    backgroundColor: "rgb(0,123,255,0.1)",
    borderColor: "rgb(0,123,255)",
    data: [3, 2, 3, 2, 4, 5, 4],
  },
  {
    backgroundColor: "rgba(0, 184, 216, 0.1)",
    borderColor: "rgb(0, 184, 216)",
    data: [1, 2, 1, 3, 5, 4, 7],
  },
  {
    backgroundColor: "rgba(23,198,113,0.1)",
    borderColor: "rgb(23,198,113)",
    data: [1, 2, 3, 3, 3, 4, 4],
  },
  {
    backgroundColor: "rgba(255,180,0,0.1)",
    borderColor: "rgb(255,180,0)",
    data: [2, 3, 3, 3, 4, 3, 3],
  },
  {
    backgroundColor: "rgba(255,65,105,0.1)",
    borderColor: "rgb(255,65,105)",
    data: [1, 7, 1, 3, 1, 4, 8],
  },
  {
    backgroundColor: "rgb(0,123,255,0.1)",
    borderColor: "rgb(0,123,255)",
    data: [3, 2, 3, 2, 4, 5, 4],
  },
  {
    backgroundColor: "rgba(0, 184, 216, 0.1)",
    borderColor: "rgb(0, 184, 216)",
    data: [1, 2, 1, 3, 5, 4, 7],
  },
  {
    backgroundColor: "rgba(23,198,113,0.1)",
    borderColor: "rgb(23,198,113)",
    data: [1, 2, 3, 3, 3, 4, 4],
  },
  {
    backgroundColor: "rgba(255,180,0,0.1)",
    borderColor: "rgb(255,180,0)",
    data: [2, 3, 3, 3, 4, 3, 3],
  },
  {
    backgroundColor: "rgba(255,65,105,0.1)",
    borderColor: "rgb(255,65,105)",
    data: [1, 7, 1, 3, 1, 4, 8],
  },
  {
    backgroundColor: "rgb(0,123,255,0.1)",
    borderColor: "rgb(0,123,255)",
    data: [3, 2, 3, 2, 4, 5, 4],
  },
];
export const smallChart_emptyLabels = [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];
// FIXED FOR LINE CHART
export const lineChartOptions = {
  responsive: true,
  legend: {
    position: "top",
  },
  elements: {
    line: {
      // A higher value makes the line look skewed at this ratio.
      tension: 0.3,
    },
    point: {
      radius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: false,
        ticks: {
          callback(tick, index) {
            // Jump every 7 values on the X axis labels to avoid clutter.
            return tick;
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          suggestedMax: 45,
          callback(tick) {
            if (tick === 0) {
              return tick;
            }
            // Format the amounts using Ks for thousands.
            return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
          },
        },
      },
    ],
  },
  hover: {
    mode: "nearest",
    intersect: false,
  },
  tooltips: {
    custom: false,
    mode: "nearest",
    intersect: false,
  },
};
export const mainLineStyle = {
  fill: "start",
  backgroundColor: "rgba(0,123,255,0.1)",
  borderColor: "rgba(0,123,255,1)",
  pointBackgroundColor: "#ffffff",
  pointHoverBackgroundColor: "rgb(0,123,255)",
  borderWidth: 1.5,
  pointRadius: 0,
  pointHoverRadius: 3,
};
export const otherLineStyle = {
  fill: "start",
  pointBackgroundColor: "#ffffff",
  borderDash: [3, 3],
  borderWidth: 1,
  pointRadius: 0,
  pointHoverRadius: 2,
};
export const otherLineColors = [
  {
    backgroundColor: "rgba(255,65,105,0.1)",
    borderColor: "rgba(255,65,105,1)",
    pointHoverBackgroundColor: "rgba(255,65,105,1)",
    pointBorderColor: "rgba(255,65,105,1)",
  },
  {
    backgroundColor: "rgba(255,215,0,0.1)",
    borderColor: "rgba(255,215,0,1)",
    pointHoverBackgroundColor: "rgba(255,215,0,1)",
    pointBorderColor: "rgba(255,215,0,1)",
  },
  {
    backgroundColor: "rgba(0,255,0,0.1)",
    borderColor: "rgba(0,255,0,1)",
    pointHoverBackgroundColor: "rgba(0,255,0,1)",
    pointBorderColor: "rgba(0,255,0,1)",
  },
  {
    backgroundColor: "rgba(128,0,128,0.1)",
    borderColor: "rgba(128,0,128,1)",
    pointHoverBackgroundColor: "rgba(128,0,128,1)",
    pointBorderColor: "rgba(128,0,128,1)",
  },
  {
    backgroundColor: "rgba(0,0,0,0.1)",
    borderColor: "rgba(0,0,0,1)",
    pointHoverBackgroundColor: "rgba(0,0,0,1)",
    pointBorderColor: "rgba(0,0,0,1)",
  },
  {
    backgroundColor: "rgba(139,69,19,0.1)",
    borderColor: "rgba(139,69,19,1)",
    pointHoverBackgroundColor: "rgba(139,69,19,1)",
    pointBorderColor: "rgba(139,69,19,1)",
  },
];

// FIXED FOR PIE CHART
export const pieChart_options = {
  legend: {
    position: "bottom",
    labels: {
      padding: 25,
      boxWidth: 20,
    },
  },
  cutoutPercentage: 0,
  tooltips: {
    custom: false,
    mode: "index",
    position: "nearest",
  },
};

export const backgroundColor = [
  "rgba(0,123,255,0.9)",
  "rgba(0,123,255,0.5)",
  "rgba(0,123,255,0.3)",
  "rgba(0,191,255,0.9)",
  "rgba(0,191,255,0.5)",
  "rgba(0,191,255,0.3)",
  "rgba(0,0,255,0.3)",
];
