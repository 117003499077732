import React from "react";
import pull from 'lodash/pull';
import classNames from "classnames";
import "./style.css";
import { Constant } from "../../lib/Constants";


const Checks = ({
  check,
  isActive,
  checkDetail,
  handleClick,
  unRequestedChecks,
  setUnRequestedChecks,
}) => {
  let status = "";
  let classes = "";
  if (checkDetail) {
    status = checkDetail.check_completed_status;
    classes = classNames("layer", {
      orange_tint: [Constant.CANDIDATE_STATUS.AMBER].includes(status),
      green_tint: [Constant.CANDIDATE_STATUS.COMPLETED].includes(status),
      red_tint: [Constant.CANDIDATE_STATUS.DISCREPANCY_FOUND, Constant.CANDIDATE_STATUS.MINOR_DISCREPANCY_FOUND].includes(status),
      yellow_tint: [
        Constant.CANDIDATE_STATUS.AMBER,
        Constant.CANDIDATE_STATUS.INSUFFICIENT,
        Constant.CANDIDATE_STATUS.ON_HOLD,
        Constant.CANDIDATE_STATUS.CASE_STOPPED,
      ].includes(status)
    });
  }

  const clicked = () => {
    if (handleClick && isActive) {
      handleClick(checkDetail);
    }
    // Can we de-select checks
    if (setUnRequestedChecks && check.name) {
      setUnRequestedChecks((prev) => {
        if(prev.includes(check.name)) {
          return pull([...prev], check.name);
        }
        return [check.name, ...prev];
      });
    }
  };

  let condition = !(unRequestedChecks && unRequestedChecks.includes(check.name));

  return (
    <div
      className="check_main_div"
      style={{
        opacity: isActive && condition ? "1" : "0.3",
      }}
    >
      <div
        className={classes}
        style={{
          border: "1px solid #000",
          cursor: isActive ? "pointer" : "default",
        }}
        onClick={() => {
          if (isActive) clicked();
        }}
      >
        <div
          className="check_image_div"
          style={{
            backgroundSize: "contain",
            backgroundImage: `url(${check.image})`,
          }}
        >
          <div className={classes}></div>
        </div>
      </div>
      <span
        className="imageText"
        style={{
          cursor: isActive ? "pointer" : "default",
          textTransform: "capitalize",
          textAlign: "center",
        }}
        onClick={() => {
          if (isActive) clicked();
        }}
      >
        {check?.label || ""}
      </span>
    </div>
  );
};

export default Checks;
